import React, { useState, useRef, useEffect, useContext } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import Questions from "./components/questions"
import Polls from "./components/polls"
import { clearClientData } from "../../services/auth"
import { TOKEN_EXPIRED } from "../../utils/constants"
import { useQuery } from "react-query"
import { pageASideContext } from "../../context/pageASide"
import { SocketContext } from "../../context/socket"

const PageAside = props => {
  const userId = JSON.parse(window?.localStorage?.veUser)?.userId;
  const [pageAsideState, setpageAsideState] = useContext(pageASideContext)
  const [pollSpin, setPollSpin] = useState(false)
  const [questionsSpin, setQuestionsSpin] = useState(false)
  const [activeTab, setActiveTab] = useState("")
  const [chatopen, toggleChat] = useState(false)
  const [poll, setpoll] = useState({})
  const [questionsData, setQuestionsData] = useState(null)

  const [isUpvotesEnabled, setIsUpvotesEnabled] = useState(props.isUpvotesEnabled)
  const { socket } = useContext(SocketContext);

  const {
    isLoading: pollsIsLoading,
    error: pollsLoadingError,
    data: pollsData = { result: { poll: [] } },
    refetch: pollsRefetch,
  } = useQuery("polls", async () => {
    if (!props.isPollTabVisible) {
      return
    }
    const currentTime = Date.now()
    let response = await fetch(
      `${process.env.API_URL}/v2/${process.env.EVENT_ID}/polls`
    )
    if (response.status !== 200 && response.error === TOKEN_EXPIRED) {
      clearClientData()
    }
    response = await response.json()
    const afterFetch = Date.now()
    setTimeout(() => {
      setPollSpin(false)
    }, 1000 - (afterFetch - currentTime))
    return response
  }, {
    refetchInterval: 5000,
  })
  const pollSubmit = async ({ selectionArray, presentationId, pollId }) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        pollId,
        poll: selectionArray,
        eventId: process.env.EVENT_ID
      }),
    }
    const response = await fetch(
      process.env.API_URL + "/v2/" + presentationId + "/polls",
      requestOptions
    ).then(res => {
      pollsRefetch()
      res.json()
    })

    if (
      response &&
      response.status === 200 &&
      response.result &&
      response.result.poll
    ) {
      setpoll(response.result)
    }
  }

  const fetchQuestions = async () => {
    const currentTime = Date.now()
    let response = await fetch(
      `${process.env.API_URL}/${process.env.EVENT_ID}/${props.presentationId}/questions?moderated=${props.moderatedQuestions}`
    )
    response = await response.json()
    if (response && response.result) {
      const afterFetch = Date.now()
      setTimeout(() => {
        setQuestionsSpin(false)
      }, 1000 - (afterFetch - currentTime))
      setQuestionsData(response.result)
      setIsUpvotesEnabled(response.isUpvotesEnabled);
      initializeSocketListener(response.result);
    }
  }

  const updateQuestionsList = newQuestion => {
    questionsData.push(newQuestion)
    setQuestionsData(questionsData => [...questionsData])
  }

  const initializeSocketListener = (questionsData) => {
    if (!socket) return;
    const channel = socket.subscribe(props.presentationId);
    let prevData = [...questionsData];
    channel.watch((data) => {
      if (data?.socketId !== socket.id && data?.event === "updatedQuestionCount") {
        let newData = [...questionsData];
        const questionIndex = newData?.findIndex((question) => question._id === data.updatedQuestionData._id);
        if(questionIndex !== -1){
          if(data.updatedQuestionData.action === "upvote" && !newData[questionIndex]?.upvotes?.includes(data.userId)){
            newData[questionIndex].upvotes.push(data.userId);
          }else if(data.updatedQuestionData.action === "downvote"){
            newData[questionIndex].upvotes = newData[questionIndex].upvotes.filter(id => id !== data.userId);
          }
        }
        setQuestionsData(newData);
      }
      else if (data?.event === "questionApproved") {
        prevData.push(data.message);
        setQuestionsData(prevData)
      } else if (data?.event === "deleteQuestion") {
        prevData = prevData.filter(question => question._id !== data.message);
        setQuestionsData(prevData)
      }
    });
  }

  async function emitDataToIL(data){
    if(!socket) return;
    socket.emit("addQuestion",data);
  }

  async function updateVotesInRedis(updatedQuestionData) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        userId,
        action: updatedQuestionData.action,
        presentationId: props.presentationId
      }),
    };

    const response = await fetch(
      `${process.env.API_URL}/${process.env.EVENT_ID}/${updatedQuestionData._id}/toggleQuestionVote`,
      requestOptions
    );
    const res = await response.json();

    if (res?.status === 200) {
      updatedQuestionData.upvotes = res?.result?.updatedData || updatedQuestionData.upvotes;
      console.log("updatedQuestionData",updatedQuestionData); 
      socket.emit("toggleQuestionVote", {
        userId,
        updatedQuestionData,
        socketId: socket.id,
        presentationId: props.presentationId,
      });
    }
  }

  const updateUpvotes = (qIndex) => {
    let action = "";
    const updatedQuestionsData = [...questionsData];
    const qid = updatedQuestionsData[qIndex]._id;
    let upvotes = updatedQuestionsData[qIndex]?.upvotes || [];
    if (upvotes?.includes(userId)) {
      upvotes = upvotes?.filter(id => id !== userId);
      action = "downvote";
    } else if(!upvotes?.includes(userId)){
      upvotes.push(userId);
      action = "upvote";
    }
    updatedQuestionsData[qIndex].upvotes = upvotes;
    setQuestionsData(updatedQuestionsData);
    const updatedQuestionData = {
      index: qIndex,
      _id: qid,
      action,
      upvotes: updatedQuestionsData[qIndex].upvotes
    };
    updateVotesInRedis(updatedQuestionData);
  }

  useEffect(() => {

    if (props.isPollTabVisible) {
      setActiveTab("poll");
    }
    else if (props.isQuestionTabVisible) {
      setActiveTab("questions");
    } else if (props.hasLiveChat) {
      setActiveTab("rcchat");
    }

    if (props.isQuestionTabVisible) {
      fetchQuestions();
    }

  }, [])

  return (
    <>
      <button className={`page-aside-toggle ${pageAsideState ? '' : 'page-aside-toggle-rotate'}`} type="button" onClick={() => {
        setpageAsideState(p => !p)
      }}>
        <FontAwesomeIcon
          icon={faChevronRight}
          className={"svg color-monochrome-2 text-size-regular chevron-right"}
        />
      </button>
      <aside
        className={`page-aside xs-between xs-column background-color-monochrome-1 ${props.className} ${pageAsideState ? '' : 'page-aside-closed'}`}
      >
        <div className={"tabs-widget"}>
          <div className={"tabs-header d-xs-flex xs-row"}>
            {
              (props.isPollTabVisible && <button
                onClick={() => setActiveTab("poll")}
                className={`button _a_tabs ${activeTab === "poll" ? "button--primary" : ""
                  } ${props.isQuestionTabVisible || props.hasLiveChat ? "" : "width-full"}`}
              >
                Poll
              </button>)
            }
            {
              (props.isQuestionTabVisible && <button
                onClick={() => setActiveTab("questions")}
                className={`button _a_tabs ${activeTab === "questions" ? "button--primary" : ""
                  } ${props.isPollTabVisible || props.hasLiveChat ? "" : "width-full"}`}
              >
                Questions
              </button>)
            }
            {
              (props.hasLiveChat && <button
                onClick={() => setActiveTab("rcchat")}
                className={`button _a_tabs ${activeTab === "rcchat" ? "button--primary" : ""
                  } ${props.isQuestionTabVisible || props.isPollTabVisible ? "" : "width-full"}`}
              >
                Chat
              </button>)
            }
          </div>
          <div
            className={`tabs-content  ${chatopen ? "tabs-content--short" : ""}`}
          >
            {activeTab === "poll" && props.isPollTabVisible && (
              <Polls
                poll={pollsData?.result?.questions}
                presentationId={pollsData?.result?.presentation_id}
                pollSubmit={pollSubmit}
                isVoted={pollsData?.result?.isVoted}
                status={pollsData?.status}
                fetchPolls={pollsRefetch}
                spin={pollSpin}
                setSpin={setPollSpin}
                pollId={pollsData?.result?._id}
              />
            )}
            {activeTab === "questions" && props.isQuestionTabVisible && (
              <Questions
              presentationId={props.presentationId}
              moderatedQuestions={props.moderatedQuestions}
              questionsData={questionsData}
              updateUpvotes={updateUpvotes}
              updateQuestionsList={updateQuestionsList}
              fetchQuestions={fetchQuestions}
              spin={questionsSpin}
              emitDataToIL={emitDataToIL}
              isUpvotesEnabled={isUpvotesEnabled}
              setSpin={setQuestionsSpin}
            />

            )}
            {activeTab === "rcchat" && props.hasLiveChat && (
              (
                <div style={{ width: "100%", height: "100%" }}>
                  <iframe style={{ width: "100%", height: "100%" }} className="resp-iframe networkingIframe" src={`${process.env.ROCKET_CHAT_URL}/channel/general?layout=embedded`} gesture="media" allow="encrypted-media" allowFullScreen></iframe>
                </div>
              )
            )}
          </div>
        </div>
      </aside>
    </>
  )
}

export default PageAside
