import React, { useState, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSync } from "@fortawesome/free-solid-svg-icons"

const Polls = props => {
  const {
    fetchPolls,
    setSpin,
    spin,
    poll = [],
    status,
    pollSubmit,
    pollId,
  } = props
  const [selectedOption, setselectedOption] = useState({})
  const [notSelectedOption, setNotSelectedOption] = useState([])

  useEffect(() =>{
    //Clearing user's selected answers and non-selected answers(used for required validation) when poll changes
    setselectedOption({});
    setNotSelectedOption([]);
  },[pollId])

  return (
    <div className="tabs-scrollable-wrapper pl-15 pr-15 pt-15 pb-15">
      {props.isVoted && (
        <>
          <div className="xs-between d-xs-flex xs-baseline">
            <h5
              className={
                "font-secondary-bold text-size-regular color-secondary-2 mb-10 position-relative"
              }
            >
              Poll Results
            </h5>
            <button
              className="icon refresh-icon icon-size-25 color-monochrome-1 background-color-primary-2 text-align-center"
              onClick={() => {
                setSpin(true)
                fetchPolls()
              }}
            >
              <FontAwesomeIcon icon={faSync} spin={spin} />
            </button>
          </div>
          <hr className="color-secondary-1"></hr>
        </>
      )}
      {poll && poll.length ? (
        poll.map((pollData, questionIndex) => {
          return (
            <div
              data-id={pollData._id}
              className={`checkbox-group ${
                notSelectedOption.includes(pollData._id) ? "error" : ""
              }`}
              key={pollData._id}
            >
              <label className="form-item__label font-secondary-bold color-secondary-2">
                {pollData && pollData.question ? pollData.question : ""}
              </label>

              {!props.isVoted && (
                <div>
                  {pollData &&
                    pollData.answers &&
                    pollData.answers.length &&
                    pollData.answers.map((answer, ansIndex) => {
                      return (
                        <div
                          className="form-item mb-0"
                          key={answer._id}
                        >
                          <div className="form-item__control radio">
                            <input
                              type="radio"
                              name={`radio-${questionIndex}`}
                              className="form-control"
                              id={`radio-${questionIndex}-${ansIndex}`}
                              data_id={answer._id}
                              data_question_id={pollData._id}
                              onChange={event => {
                                const newNotSelectedOption = notSelectedOption.filter(
                                  option => {
                                    if (option !== pollData._id) return true
                                  }
                                )
                                setNotSelectedOption(newNotSelectedOption)
                                setselectedOption({
                                  ...selectedOption,
                                  [event.currentTarget.attributes
                                    .data_question_id.value]:
                                    event.currentTarget.attributes.data_id
                                      .value,
                                })
                              }}
                            />
                            <label
                              className="form-item__label"
                              htmlFor={`radio-${questionIndex}-${ansIndex}`}
                              aria-label={answer.value}
                            >
                              <span
                                className="icon icon-size-14 radius-circle"
                                aria-hidden="true"
                              >
                                <i className="svg"></i>
                              </span>
                              <span
                                className="form-item__label-text font-secondary-regular"
                                aria-hidden="true"
                              >
                                {answer.value}
                              </span>
                            </label>
                          </div>
                        </div>
                      )
                    })}
                </div>
              )}

              {props.isVoted && (
                <div className="mb-10">
                  {pollData.answers.map((answer, ansIndex) => {
                    return (
                      <div className="feedback-group mb-10" key={ansIndex}>
                        <div className="feedback-label mb-5">
                          <span className="answer-text font-secondary-regular d-xs-flex xs-between">
                            <span>{answer.value}</span>
                            <span className="pl-10">
                              {answer.percentage.toFixed(2)}%
                            </span>
                          </span>
                        </div>
                        <div className="answer-feedback">
                          <div
                            className="answer-feedback-bar"
                            style={{
                              width: answer.percentage.toFixed(2) + "%",
                            }}
                          ></div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              )}
              <hr className="color-secondary-1"></hr>
            </div>
          )
        })
      ) : !!poll && status === 200 ? (
        <p>Polls will be shown here during the event.</p>
      ) : (
        ""
      )}
      {poll && poll.length
        ? !props.isVoted && (
            <div className="mt-10 mb-10">
              <input
                type="submit"
                className={"button button--primary _a_vote"}
                value={"Vote"}
                onClick={() => {
                  if (poll.length !== Object.keys(selectedOption).length) {
                    const setNotSelectedOptionarray = []
                    poll.filter(poll => {
                      if (!selectedOption[poll._id])
                        setNotSelectedOptionarray.push(poll._id)
                    })
                    setNotSelectedOption(setNotSelectedOptionarray)
                  } else {
                    const selectionArray = []
                    for (const key in selectedOption) {
                      selectionArray.push({
                        questionId: key,
                        answerId: selectedOption[key],
                      })
                    }
                    pollSubmit({
                      selectionArray,
                      presentationId: props.presentationId,
                      pollId,
                    })
                  }
                }}
              />
            </div>
          )
        : ""}
    </div>
  )
}

export default Polls
